import React, { useEffect, useState } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid,
    Box,
    Typography,
    FormHelperText,
    OutlinedInput,
    Chip,
} from '@mui/material';
import FormTemplate from './FormTemplate';
import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ConditionBuilder from '../components/ConditionBuilder';
import MultiSelectWithSelectAll from '../components/MultiSelectWithAll';

const NodesDynamicForm = ({ formFields, title, onSubmit, initialData = {} }) => {
    const [valid, setValid] = useState(false);
    const [touched, setTouched] = useState([]);
    const [formData, setFormData] = useState(() => {
        const initialState = formFields.reduce((acc, field) => {
            if (field.type === 'checkboxGroup') {
                acc[field.id] = [];
            } else if (field.type === 'checkbox') {
                acc[field.id] = field.defaultValue || false;
            } else {
                acc[field.id] = '';
            }
            return acc;
        }, {});

        return {
            ...initialState,
            ...Object.fromEntries(
                Object.entries(initialData).map(([key, value]) => {
                    if (Array.isArray(value)) {
                        if (value.length > 0 && value[0].hasOwnProperty('value')) {
                            return [key, value];
                        }
                        const field = formFields.find((f) => f.id === key);
                        if (field && field.type === 'checkboxGroup') {
                            return [key, value];
                        }
                    }
                    return [key, value ?? initialState[key]];
                })
            ),
        };
    });

    const handleTouched = (field) => {
        setTouched((prev) => ({
            ...prev, [field]: true
        }))
    }

    const handleChange = (field) => (event) => {
        if (field.type === 'checkboxGroup') {
            const { value, checked } = event.target;
            setFormData((prevData) => {
                const currentValues = Array.isArray(prevData[field.id]) ? prevData[field.id] : [];

                const updatedValues = checked
                    ? [...currentValues, value]
                    : currentValues.filter((v) => v !== value);
                return { ...prevData, [field.id]: updatedValues };
            });
        } else if (field.type === 'checkbox') {
            setFormData({
                ...formData,
                [field.id]: event.target.checked,
            });
        } else {
            setFormData({
                ...formData,
                [field.id]: event.target.value,
            });
        }
    };

    const handleSubmit = () => {
        const updatedFormData = { ...formData };

        // Helper function: Convert a raw item (primitive or object) 
        // into { value, label } only if needed.
        function coerceToValueLabel(field, item) {
            // If it's already { value, label }, keep it as is.
            if (typeof item === 'object' && item.value !== undefined) {
                return item;
            }

            // Otherwise, find the matching option to get a label.
            const option = field.options.find((opt) => opt.value === item);
            return {
                value: item,
                label: option ? option.label : item,
            };
        }

        formFields.forEach((field) => {
            // Only run this logic for selects and checkboxGroups
            if (field.type === 'select' || field.type === 'checkboxGroup') {
                const currentValue = updatedFormData[field.id];

                // Multi-select or checkboxGroup => array
                if (Array.isArray(currentValue)) {
                    updatedFormData[field.id] = currentValue.map((item) =>
                        coerceToValueLabel(field, item)
                    );
                }
                // Single-select => a single value
                else if (currentValue) {
                    updatedFormData[field.id] = coerceToValueLabel(field, currentValue);
                }
            }
        });

        // Pass the updated form data to the parent
        onSubmit(updatedFormData);
    };


    const handleSelectAll = (field) => (event) => {
        const { checked } = event.target;
        setFormData((prev) => ({
            ...prev,
            [field.id]: checked ? field.options.map((option) => option.value) : [],
        }));
    };

    const isFormValid = () => {
        return formFields.every((field) => {
            if (field.required) {
                const value = formData[field.id];
                if (field.type === 'checkboxGroup') {
                    return Array.isArray(value) && value.length > 0;
                }
                return Boolean(value);
            }
            return true;
        });
    };

    useEffect(() => {
        setValid(isFormValid())
    }, [formData])

    return (
        <FormTemplate title={title} handleSubmit={handleSubmit} isValid={valid}>
            <Grid
                container
                spacing={2}
                sx={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                    width: '100%',
                    padding: '16px',
                    bgcolor: 'background.paper'
                }}
            >
                {formFields.map((field) => {
                    switch (field.type) {
                        case 'text':
                        case 'email':
                        case 'number':
                            return (
                                <Grid item xs={12} key={field.id} sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <TextField
                                        label={field.label}
                                        type={field.type}
                                        value={formData[field.id]}
                                        onChange={handleChange(field)}
                                        required
                                        onBlur={(e) => {
                                            handleTouched(field.id)
                                        }}
                                        error={!formData[field.id] && touched[field.id]}
                                        helperText={touched[field.id] && !formData[field.id] ? 'Field is required' : ''}
                                    />
                                </Grid>
                            );
                        case 'select':
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={field.id}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <FormControl sx={{ minWidth: 300 }}
                                        onBlur={(e) => {
                                            handleTouched(field.id)
                                        }}
                                        error={!formData[field.id] && touched[field.id]}
                                        required>
                                        <InputLabel id={`${field.id}-label`}>{field.label}</InputLabel>
                                        <Select
                                            labelId={`${field.id}-label`}
                                            id={`${field.id}-select`}
                                            value={formData[field.id]?.value || formData[field.id] || ''}
                                            label={field.label}
                                            onChange={handleChange(field)}


                                        >
                                            <MenuItem key={`${field.id}-empty`} value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {field.options
                                                ?.filter(
                                                    (option) =>
                                                        option && option.value != null && option.label != null
                                                )
                                                .map((option) => (
                                                    <MenuItem key={`${field.id}-${option.value}`} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText>{touched[field.id] && !formData[field.id] ? 'Field is required' : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            );
                        case 'checkbox':
                            return (
                                <Grid item xs={12} key={field.id} sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData[field.id]}
                                                onChange={handleChange(field)}
                                            />
                                        }
                                        label={field.label}
                                    />
                                </Grid>
                            );

                        case "checkboxGroup":
                            return (
                                <Grid item xs={12} key={field.id} sx={{
                                    width: "100%",
                                    maxWidth: "100%",
                                }}>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            maxWidth: "100%",
                                            margin: "0 auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2,
                                        }}
                                    >
                                        <Typography
                                            variant="h10"
                                            sx={{ textAlign: "left", color: "text.secondary" }}
                                        >
                                            {field.label}
                                        </Typography>

                                        {field.options.length === 0 ? (
                                            <Typography
                                                variant="body1"
                                                align="left"
                                                color="text.secondary"
                                                sx={{ mt: 2 }}
                                            >
                                                No data found!
                                            </Typography>
                                        ) : (
                                            <MultiSelectWithSelectAll
                                                label={field.label}
                                                options={field.options}              // e.g. [{ value: 5342, label: "Foo" }, ...]
                                                value={formData[field.id] || []}      // e.g. an array of selected IDs
                                                onChange={(newValues) => {
                                                    // newValues is an array of strings/numbers
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [field.id]: newValues,
                                                    }));
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            );


                        case 'timeRange':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            maxWidth: '800px',
                                            margin: 0,                // <-- remove '0 auto' to avoid horizontal centering
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2,
                                            mt: 2,
                                            mb: 2,
                                            alignItems: 'flex-start', // <-- aligns children (the Typography, etc.) to the left
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                textAlign: 'left',      // <-- ensure text is left-aligned
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {field.label}
                                        </Typography>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <SingleInputTimeRangeField
                                                label="Select Time Range"
                                                value={[
                                                    formData[field.id]?.[0]
                                                        ? dayjs(formData[field.id][0], 'HH:mm')
                                                        : null,
                                                    formData[field.id]?.[1]
                                                        ? dayjs(formData[field.id][1], 'HH:mm')
                                                        : null,
                                                ]}
                                                onChange={(newValue) => {
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        [field.id]: newValue
                                                            ? [
                                                                newValue[0]?.format('HH:mm'),
                                                                newValue[1]?.format('HH:mm'),
                                                            ]
                                                            : ['00:00', '00:00'],
                                                    }));
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: '400px',
                                                    // margin: '0 auto', <-- remove/disable this to avoid centering
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>

                            );
                        case 'conditionBuilder':
                            return (
                                <Grid item xs={12} key={field.id}>
                                    <ConditionBuilder
                                        value={formData[field.id]}
                                        onChange={(value) => setFormData({
                                            ...formData,
                                            [field.id]: value
                                        })}
                                        conditionTypes={field.conditionTypes}
                                    />
                                </Grid>
                            );
                        default:
                            return null;
                    }
                })}
            </Grid>
        </FormTemplate>
    );
};

export default NodesDynamicForm;
