// components/CreateAutomationForm.js
import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import { getInitialFormState } from '../utils/transformers/automationTransformers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function CreateAutomationForm({
    automationData: initialAutomationData,
    setAutomationData: setParentAutomationData
}) {
    const [formData, setFormData] = useState(initialAutomationData || getInitialFormState());
    const [touched, setTouched] = useState([]);

    const handleInputChange = (field, value) => {
        const newData = {
            ...formData,
            [field]: value,
            // Initialize nested objects if they don't exist
            schedule: formData.schedule || {},
            settings: formData.settings || {},
            metrics: formData.metrics || { Executions: 0, Successes: 0, Failures: 0 },
            actions: formData.actions || {
                Id: 0,
                Type: '',
                To: '',
                Subject: '',
                Body: '',
                Attachments: ''
            },
        };
        setFormData(newData);
        setParentAutomationData?.(newData);
    };

    const handleScheduleChange = (field, value) => {
        const newData = {
            ...formData,
            schedule: {
                ...formData.schedule,
                [field]: value ? value.toISOString() : null,
                LastRun: formData.schedule?.start || value
            }
        };
        setFormData(newData);
        setParentAutomationData?.(newData);
    };

    const handleTouched = (field) => {
        setTouched((prev) => ({
            ...prev, [field]: true
        }))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* Name */}
            <TextField
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                required
                value={formData.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                onBlur={(e) => {
                    handleTouched('name')
                }}
                error={!formData.name && touched.name}
                helperText={touched.name && !formData.name ? 'Name is required' : ''}
            />


            {/* Description */}
            <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                value={formData.description || ''}
                onBlur={() => handleTouched('description')}
                error={touched.description && !formData.description}
                helperText={touched.description && !formData.description ? 'Description is required' : ''}
                onChange={(e) => handleInputChange('description', e.target.value)}
            />

            {/* Status */}
            <FormControl fullWidth margin="dense" required
                onBlur={(e) => {
                    handleTouched('status')
                }}
                error={!formData.status && touched.status}>
                <InputLabel>Status</InputLabel>
                <Select
                    value={formData.status || ''}
                    onChange={(e) => handleInputChange('status', e.target.value)}
                    label="Status"
                >
                    <MenuItem value="Disabled">Disabled</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
                <FormHelperText>{!formData.status && touched.status ? "Status is required" : ""}</FormHelperText>
            </FormControl>

            {/* Priority */}
            <FormControl fullWidth margin="dense" required
                onBlur={(e) => {
                    handleTouched('priority')
                }}
                error={!formData.priority && touched.priority}>
                <InputLabel>Priority</InputLabel>
                <Select
                    value={formData.priority || ''}
                    onChange={(e) => handleInputChange('priority', e.target.value)}
                    label="Priority"
                >
                    <MenuItem value="High" sx={{ color: 'error.main' }}>High</MenuItem>
                    <MenuItem value="Low" sx={{ color: 'success.main' }}>Low</MenuItem>
                </Select>
                <FormHelperText>{!formData.priority && touched.priority ? 'Priority is required' : ''}</FormHelperText>
            </FormControl>


            {/* Start Date */}
            <DateTimePicker
                label="Start Date"
                value={formData.schedule?.start ? dayjs(formData.schedule.start) : null}
                onChange={(newValue) => handleScheduleChange('start', newValue)}
                ampm={false}
                minDateTime={dayjs()}
                slotProps={{
                    textField: {
                        margin: 'dense',
                        fullWidth: true,
                        required: true,
                        onBlur: () => handleTouched('scheduleStart'),
                        error: touched.scheduleStart && (
                            !formData.schedule?.start ||
                            dayjs(formData.schedule.start).isBefore(dayjs())
                        ),
                        helperText: touched.scheduleStart && (
                            !formData.schedule?.start
                                ? 'Start date is required'
                                : dayjs(formData.schedule.start).isBefore(dayjs())
                                    ? 'Start date cannot be in the past'
                                    : ''
                        ),
                    },
                    popper: {
                        sx: {
                            '& .MuiPickersLayout-root': {
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                minWidth: '200px',
                            },
                            '& .MuiPickersLayout-actionBar': {
                                gridColumn: '2/3',
                            },
                        },
                    },
                }}
            />

            {/* End Date */}
            <DateTimePicker
                label="End Date"
                value={formData.schedule?.end ? dayjs(formData.schedule.end) : null}
                onChange={(newValue) => handleScheduleChange('end', newValue)}
                ampm={false}
                minDateTime={formData.schedule?.start ? dayjs(formData.schedule.start) : dayjs()}
                slotProps={{
                    textField: {
                        margin: 'dense',
                        fullWidth: true,
                        required: true,
                        onBlur: () => handleTouched('scheduleEnd'),
                        error: touched.scheduleEnd && (
                            !formData.schedule?.end ||
                            (formData.schedule?.start && dayjs(formData.schedule.end).isBefore(dayjs(formData.schedule.start)))
                        ),
                        helperText: touched.scheduleEnd && (
                            !formData.schedule?.end
                                ? 'End date is required'
                                : formData.schedule?.start && dayjs(formData.schedule.end).isBefore(dayjs(formData.schedule.start))
                                    ? 'End date cannot be before Start date'
                                    : ''
                        ),
                    },
                    popper: {
                        sx: {
                            '& .MuiPaper-root': {
                                padding: '0 8px',
                            },
                        },
                    },
                }}
            />
        </LocalizationProvider>
    );
}
