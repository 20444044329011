import React, { useEffect, useState } from 'react';
import { DialogTitle, Drawer } from '@mui/material';
import { getNodeFormFields } from '../../../utils/workflow/getNodeFormFields';
import NodesDynamicForm from '../../../forms/NodesDynamicForm';
import { useParams } from 'react-router-dom';
import useAutomations from '../../../hooks/automationhooks/useAutomations';
import SMSSideMenu from './SMSSideMenu';
import WhatsappSideMenu from './WhatsappSideMenu';
import nodeTypesConfig from '../../../models/nodeTypesConfig';
import { useThemeContext } from '../../../Theme/Theme';

function NodeSideMenu({ open, selectedNode, onSave, onCancel, setMenu }) {
    const [formFields, setFormFields] = useState([]);
    const { brandSegments, whatsappNumbers, emails, campaigns, handleGetEmails, handleGetCampaigns, handleGetBrandSegments, handleGetWhatsappNumbers } = useAutomations();
    const { automationId, brandId } = useParams();
    const { mode } = useThemeContext();

    useEffect(() => {
        if (open && selectedNode) {
            setFormFields([]);
            const type = selectedNode.type;
            const fields = getNodeFormFields(type, nodeTypesConfig, brandSegments, campaigns, whatsappNumbers);
            setFormFields(fields || []);
        }
    }, [open, selectedNode, brandSegments, campaigns]);

    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetCampaigns(currentBrandId);
        }
    }, [brandId]);

    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetBrandSegments(currentBrandId);
        }
    }, [brandId]);

    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetWhatsappNumbers(currentBrandId);
        }
    }, [brandId]);

    const handleSubmit = (value) => {
        switch (selectedNode.type) {
            case 'smsMessage':
                if (value) {
                    selectedNode.data.template.value = value.Id
                    selectedNode.data.template.label = value.TemplateName
                }
                break
            case 'whatsappMessage':
                if (value) {
                    selectedNode.data.template.value = value.TemplateId
                    selectedNode.data.template.label = value.TemplateName
                }
                break
        }
    }


    let initialData = {};
    if (selectedNode?.data) {
        initialData = structuredClone(selectedNode.data);
    }

    // If campaignSource is an array of objects, convert it to an array of IDs
    if (Array.isArray(initialData.campaignSource)) {
        initialData.campaignSource = initialData.campaignSource.map((c) =>
            typeof c === 'object' && c.value !== undefined ? c.value : c
        );
    }
    if (Array.isArray(initialData.leadType)) {
        initialData.leadType = initialData.leadType.map((c) =>
            typeof c === 'object' && c.value !== undefined ? c.value : c
        );
    }

    if (!selectedNode) return null;

    const renderNodeMenu = () => {
        switch (selectedNode.type) {
            case 'smsMessage':
                return <SMSSideMenu onClose={onCancel} onSubmit={handleSubmit} />;
            case 'whatsappMessage':
                return <WhatsappSideMenu onClose={onCancel} onSubmit={handleSubmit} />;
            default:
                return (
                    <>
                        <DialogTitle bgcolor={'background.default'} color="companyBlue">Edit Node</DialogTitle>
                        <NodesDynamicForm
                            formFields={formFields}
                            initialData={initialData}
                            onSubmit={(formData) => {
                                onSave(formData);
                                onCancel();
                            }}
                            onClose={onCancel}
                        />
                    </>
                );
        }
    };

    return (
        <Drawer
            open={open}
            onClose={onCancel}
            PaperProps={{
                sx: {
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    height: '100%',
                    margin: 0,
                    maxWidth: '400px',
                    width: '400px',
                    borderRadius: 0,
                },
            }}
        >
            {renderNodeMenu()}
        </Drawer>
    );
}

export default NodeSideMenu;
