// components/CreateAutomationDialog.js
import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import CreateAutomationForm from './CreateAutomationForm';
import dayjs from 'dayjs';

export default function CreateAutomationDialog({
    open,
    onClose,
    onSubmit,
    automationData,
    setAutomationData
}) {
    const isFormValid = () => {
        const startDate = dayjs(automationData.schedule?.start);
        const endDate = dayjs(automationData.schedule?.end);
        return (
            automationData.name &&
            automationData.status &&
            automationData.priority &&
            automationData.schedule?.start &&
            automationData.schedule?.end &&
            startDate.isBefore(endDate) &&
            startDate.isAfter(dayjs())
        );
    };


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <>
                <DialogTitle>Create Automation</DialogTitle>
                <DialogContent>
                    <CreateAutomationForm
                        automationData={automationData}
                        setAutomationData={setAutomationData}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button disabled={!isFormValid()} onClick={onSubmit} variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </>
        </Dialog>
    );
}
