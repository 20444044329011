import React, { useState, useContext, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid2,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NodeConfigModal from './menus/NodeConfigModal';
import NodesDynamicForm from "../../forms/NodesDynamicForm"
import AddNodeContext from '../../contexts/AddNodeContext';
import nodeTypesConfig from "../../models/nodeTypesConfig"
import useAutomations from '../../hooks/automationhooks/useAutomations';
import { useParams } from 'react-router-dom';
import { useThemeContext } from '../../Theme/Theme';

const AddActionButton = ({ edgeId }) => {
    const [optionsModalOpen, setOptionsModalOpen] = useState(false);
    const { whatsappNumbers, whatsappTemplates, emails, smsTemplates, handleGetSmsTemplates, handleGetWhatsappNumbers, handleGetWhatsappTemplates, handleGetEmails } = useAutomations()
    const { mode } = useThemeContext();
    const { automationId, brandId } = useParams();

    const [configModal, setConfigModal] = useState({
        open: false,
        content: null,
    });
    const { addNodeBetweenNodes } = useContext(AddNodeContext);

    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetWhatsappNumbers(currentBrandId);
            handleGetWhatsappTemplates(currentBrandId);
            handleGetSmsTemplates(currentBrandId);
        }
    }, [brandId]);
    useEffect(() => {
        const currentBrandId = brandId || localStorage.getItem('brandId');
        if (currentBrandId) {
            handleGetEmails(currentBrandId);
        }
    }, [brandId]);

    const handleOptionsButtonClick = (event) => {
        event.stopPropagation(); // Prevent edge click events
        setOptionsModalOpen(true);
    };

    const handleOptionsModalClose = () => {
        setOptionsModalOpen(false);
    };

    const handleConfigModalClose = () => {
        setConfigModal({ open: false, content: null });
    };

    const handleOptionSelect = (type) => {
        setOptionsModalOpen(false);
        const nodeTypeConfig = nodeTypesConfig.actions.find(
            (node) => node.type === type
        );

        if (!nodeTypeConfig) {
            console.error(`Unknown node type: ${type}`);
            return;
        }


        //might want to make this be a general function that loads everything on workflow initialization, then passes down to children
        if (nodeTypeConfig.formFields && nodeTypeConfig.formFields.length > 0) {
            const formFields = nodeTypeConfig.formFields.map(field => {
                if (type === 'whatsappMessage') {
                    if (field.id === 'sendFrom') {   //not making these the same if statement cause we will need to check templates too, more effient question mark?       
                        return {
                            ...field,
                            options: whatsappNumbers.data.map(number => ({
                                value: number,
                                label: number
                            }))
                        };
                    }
                    if (field.id === 'template') {
                        return {
                            ...field,
                            options: whatsappTemplates.data.Data.List.map(template => ({
                                value: template.TemplateId,
                                label: template.TemplateName
                            }))
                        }
                    }
                }
                if (type === 'emailMessage') {
                    if (field.id === 'sendFrom') {   //not making these the same if statement cause we will need to check templates too, more effient question mark?       
                        return {
                            ...field,
                            options: emails.data.map(number => ({
                                value: number,
                                label: number
                            }))
                        };
                    }
                }
                if (type === 'smsMessage') {
                    if (field.id === 'template') {
                        return {
                            ...field,
                            options: smsTemplates.data.map(template => ({
                                value: template.Id,
                                label: template.TemplateName
                            }))
                        }
                    }

                }
                return field;
            });

            setConfigModal({
                open: true,
                content: (
                    <NodesDynamicForm
                        title={`${nodeTypeConfig.displayName}`}
                        onClose={handleConfigModalClose}
                        formFields={formFields}
                        onSubmit={(formData) => {
                            addNodeBetweenNodes(type, formData, edgeId);
                            handleConfigModalClose();
                        }}
                        setConfigModal={setConfigModal}
                        handleCancel={() => setConfigModal({ open: false })}
                    />
                ),
            });

        } else {
            // If no form is required
            addNodeBetweenNodes(type, {}, edgeId);
        }
    };

    return (
        <>
            <Button
                onClick={handleOptionsButtonClick}
                variant="contained"
                size="small"
                sx={{
                    height: 20,
                    maxWidth: 20,
                    minWidth: 20,
                    borderRadius: '50%',
                    backgroundColor: mode === 'dark' ? 'grey.800' : 'grey.300', // Dynamic background
                    color: mode === 'dark' ? 'white' : 'black', // Dynamic text color
                    position: 'relative', // For positioning the hover effect
                    '&:hover': {
                        backgroundColor: mode === 'dark' ? 'grey.900' : 'grey.400', // Dynamic hover color
                        transform: 'scale(1.1)',
                        boxShadow: mode === 'dark'
                            ? '0 6px 12px rgba(0, 0, 0, 0.5)'
                            : '0 6px 12px rgba(200, 200, 200, 0.5)', // Dynamic shadow
                        '& .add-icon': {
                            opacity: 1, // Make the + icon visible
                        },
                    },
                }}
            >
                {/* Hidden AddIcon */}
                <AddIcon
                    className="add-icon"
                    sx={{
                        fontSize: 20, // Icon size
                        color: mode === 'dark' ? 'white' : 'black', // Dynamic icon color
                        opacity: 0, // Hidden by default
                        transition: 'opacity 0.2s ease', // Smooth fade-in on hover
                        position: 'absolute', // Center icon
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Button>



            {/* Options Modal */}
            <Dialog
                open={optionsModalOpen}
                onClose={handleOptionsModalClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        minWidth: '600px',
                        bgcolor: 'background.paper', // Dark background color
                        color: 'text.primary',  // Light text color
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <DialogTitle sx={{ textAlign: 'center', bgcolor: 'background.default' }} color='companyBlue'>
                    Select an Action Node
                </DialogTitle>
                <DialogContent
                    sx={{
                        p: 3,
                        pt: '24px !important',
                        pb: '24px !important',
                        overflow: 'hidden',
                        color: 'text.primary',  // Light text
                    }}
                >
                    <Grid2
                        container
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: '100%',
                            m: 0,
                            p: '12px',
                        }}
                    >
                        {nodeTypesConfig.actions.map((node) => (
                            <Grid2
                                item
                                xs={12}
                                sm={4}
                                key={node.type}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOptionSelect(node.type)}
                                    sx={{
                                        width: '180px',
                                        height: '180px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        p: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        bgcolor: 'background.default',
                                        color: 'text.primary',   // Light text color
                                        borderColor: 'rgba(255, 255, 255, 0.3)', // Subtle border
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)',
                                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                                        },
                                    }}
                                >
                                    <node.icon sx={{ fontSize: '40px', mb: 2, color: 'text.primary' }} />
                                    <div>{node.displayName}</div>
                                </Button>
                            </Grid2>
                        ))}
                    </Grid2>
                </DialogContent>
                <DialogActions sx={{ p: 3, justifyContent: 'flex-end', bgcolor: 'background.default' }}>
                    <Button
                        variant="outlined"
                        onClick={handleOptionsModalClose}
                        color={'companyBlue'}
                        sx={{
                            minWidth: 100,
                            borderColor: 'companyBlue',
                            '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                borderColor: 'rgba(255, 255, 255, 0.5)',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Configuration Modal */}
            {configModal.open && (
                <NodeConfigModal open={configModal.open} onClose={handleConfigModalClose}>
                    {configModal.content}
                </NodeConfigModal>
            )}
        </>
    );
};

export default AddActionButton;
